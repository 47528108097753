
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { Options, Vue } from "vue-class-component";
import ModalShowImage from "@/app/ui/components/modal-show-image/index.vue";

@Options({
  components: {
    ModalShowImage
  },
  props: {
    modelValue: {
      required: true
    },
    caption: {
      default: "",
      type: String
    },
    isClear: {
      default: false,
      type: Boolean
    },
    maxSize: {
      default: 256, // size in MB
      type: Number
    },
    typeCustom: {
      default: "",
      type: String
    }
  }
})
export default class UploadFile extends Vue {
  props: any = this.$props;
  isDataValidate = false;
  isDragFile = false;
  fileOriginal = "";
  pdfName = "";

  $refs!: {
    file: HTMLFormElement;
  };

  setDragAndDrop(e: any, value: boolean) {
    this.isDragFile = value;
    e.preventDefault();
  }

  onUploadImage(file: any) {
    const type = file.name.split(".").pop();
    this.pdfName = "";

    if (type === "jpg" || type === "png") {
      if (file.size > this.props.maxSize * 1024 * 1024) this.setError();
      else {
        this.fileOriginal = URL.createObjectURL(file);
        this.$emit("update:modelValue", file);
        this.isDataValidate = false;
        this.isDragFile = false;
      }
    } else if (type === "pdf") {
      this.pdfName = file.name;
      this.$emit("update:modelValue", file);
      this.isDataValidate = false;
      this.isDragFile = false;
    } else this.setDefault();
  }

  onSubmitClick(e: any) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.onUploadImage(file);
    } else this.setDefault();
  }

  // Drag File
  onSubmitDrag(e: any) {
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer && e.dataTransfer.files[0];
      this.onUploadImage(file);
    } else this.setDefault();
  }

  setDefault() {
    this.$emit("update:modelValue", "");
    this.$refs.file.value = "";
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  setError() {
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  // Watch isClear changed
  updated() {
    if (this.props.isClear) this.setDefault();
  }

  // Ellipsis String
  ellipsisString(value: string) {
    return ellipsisString(value, 50);
  }

  // Show image
  isShow = false;
  onOpenShowImage(value: boolean) {
    this.isShow = value;
  }
}
