
import { Options, Vue } from "vue-class-component";
import AddLayout from "@/app/ui/layout/add-layout.vue";
import { AnnouncementController } from "@/app/ui/controllers/AnnouncementController";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import UploadImage from "@/app/ui/components/upload-image/index.vue";
import Checkbox from "@/app/ui/components/check-box/index.vue";
import {
  formatDateWithoutTime,
  formatDateNumber,
  formatTimeNumber
} from "@/app/infrastructures/misc/Utils";
import TextEditor from "@/app/ui/components/text-editor/index.vue";

@Options({
  components: {
    AddLayout,
    UploadFile,
    UploadImage,
    Checkbox,
    TextEditor
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.isSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onOpenSuccess(false);
      next();
    }
  }
})
export default class Add extends Vue {
  // Edit terminated
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }
  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Date
  invalidDate = false;
  get yesterdayDate() {
    const date = new Date();
    return new Date(date.setDate(date.getDate()));
  }
  get releasedDate(): Date {
    return AnnouncementController.releasedDate;
  }
  get releasedDateTime(): Date {
    return AnnouncementController.releasedDateTime;
  }
  inputDateStart(param: Date) {
    AnnouncementController.setReleasedDate(param);
  }
  inputDateStartTime(param: Date) {
    AnnouncementController.setReleasedDateTime(param);
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(this.releasedDate))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidDate = false;
      } else if (param.getHours() > new Date().getHours() - 1) {
        this.invalidDate = false;
      } else {
        this.invalidDate = true;
      }
    }
  }

  // misc variable
  form = {
    name: "",
    description: "",
    attachment: "",
    bannerImage: "",
    targetUser: "",
    isPinned: false,
    maxLength: 1000
  };

  // Target User
  selectedUserTarget: Array<any> = [];
  listTargetUser = [
    { name: "POS", value: "pos" },
    { name: "Subconsolidator", value: "sub-console" },
    { name: "Internal", value: "internal" },
    { name: "Consolidator", value: "console" },
    { name: "Client", value: "client" }
  ];
  isSelected(item: any) {
    return this.selectedUserTarget.find((data: any) => {
      return data["value"] ? data["value"] === item["value"] : data === item;
    });
  }
  onChooseSelect(item: any) {
    if (this.isSelected(item)) {
      const indexItemSelected = this.selectedUserTarget.findIndex(
        (data: any) => {
          return data["value"]
            ? data["value"] === item["value"]
            : data === item;
        }
      );
      this.selectedUserTarget.splice(indexItemSelected, 1);
    } else {
      this.selectedUserTarget.push(item);
    }
  }

  // Toggle Rates Status
  isPinned = false;

  // Is Disable
  get isDisabled() {
    const textOnly = this.form.description.replace(/(<([^>]+)>)/gi, "");
    return (
      !this.form.name ||
      this.form.description === "" ||
      textOnly.length > this.form.maxLength ||
      this.selectedUserTarget.length === 0 ||
      this.invalidDate
    );
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // Loading
  get isLoading() {
    return AnnouncementController.isLoading;
  }

  // route navigation
  goBack() {
    this.$router.push(`/admin/announcement`);
  }

  // Modal Save
  get isOpenConfirm() {
    return AnnouncementController.openModal;
  }
  onOpenConfirm(value: boolean) {
    return AnnouncementController.setOpenModal(value);
  }
  get isSuccess() {
    return AnnouncementController.openSuccess;
  }
  onOpenSuccess(value: boolean) {
    AnnouncementController.setOpenSuccess(value);
  }

  // On Save to api

  _onCreate() {
    AnnouncementController._onAdd({
      name: this.form.name,
      description: this.form.description,
      releasedDate: formatDateNumber(this.releasedDate),
      releasedTime: formatTimeNumber(this.releasedDateTime),
      userTarget: this.selectedUserTarget.map((e: any) => e.value).toString(),
      bannerImage: this.form.bannerImage,
      attachment: this.form.attachment,
      pinned: this.isPinned
    });
  }
}
