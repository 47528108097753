import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createBlock(_component_Editor, {
    modelValue: _ctx.editorData,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.editorData = $event)),
    value: _ctx.value,
    maxLength: _ctx.maxLength,
    isMaxLength: _ctx.isMaxLengthValue,
    placeholder: _ctx.placeholder
  }, {
    toolbar: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.toolbar, (tool, index) => {
        return (_openBlock(), _createBlock("span", {
          class: "ql-formats",
          key: index,
          style: tool.style
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(tool.subMenu, (submenu, index) => {
            return (_openBlock(), _createBlock("button", {
              key: index,
              class: 'ql-' + submenu.className,
              style: submenu.style,
              value: submenu.value
            }, null, 14, ["value"]))
          }), 128))
        ], 4))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "value", "maxLength", "isMaxLength", "placeholder"]))
}