/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import {
  ChangeAnnouncementRequestInterface,
  EditAnnouncementRequestInterface
} from "../contracts/AnnouncementRequest";

export class AddAnnouncementApiRequest
  implements ChangeAnnouncementRequestInterface {
  private name: string;
  private description: string;
  private released_date: string;
  private released_time: string;
  private user_target: string;
  private banner_image: any;
  private attachment: any;
  private pinned: boolean;

  constructor(
    name: string,
    description: string,
    released_date: string,
    released_time: string,
    user_target: string,
    bannerImage: any,
    attachment: any,
    pinned: boolean
  ) {
    this.name = name;
    this.description = description;
    this.released_date = released_date;
    this.released_time = released_time;
    this.user_target = user_target;
    this.banner_image = bannerImage;
    this.attachment = attachment;
    this.pinned = pinned;
  }

  public toFormData() {
    const data = {
      name: this.name,
      description: this.description,
      released_date: this.released_date,
      released_time: this.released_time,
      user_target: this.user_target,
      banner_image: this.banner_image,
      attachment: this.attachment,
      pinned: this.pinned
    };
    return toFormDataUtils(data);
  }
}

export class EditAnnouncementApiRequest
  implements EditAnnouncementRequestInterface {
  private name: string;
  private description: string;
  private releaseDate: string;
  private releaseTime: string;
  private userTarget: string;
  private bannerImage: any;
  private attachment: any;
  private pinned: boolean;
  private is_delete_banner: boolean;

  constructor(
    name: string,
    description: string,
    releaseDate: string,
    releaseTime: string,
    userTarget: string,
    bannerImage: any,
    attachment: any,
    pinned: boolean,
    is_delete_banner: boolean
  ) {
    this.name = name;
    this.description = description;
    this.releaseDate = releaseDate;
    this.releaseTime = releaseTime;
    this.userTarget = userTarget;
    this.bannerImage = bannerImage;
    this.attachment = attachment;
    this.pinned = pinned;
    this.is_delete_banner = is_delete_banner;
  }
  toFormData(): FormData {
    return toFormDataUtils({
      name: this.name,
      description: this.description,
      released_date: this.releaseDate,
      released_time: this.releaseTime,
      user_target: this.userTarget,
      banner_image: this.bannerImage,
      attachment: this.attachment,
      pinned: this.pinned,
      is_delete_banner: this.is_delete_banner
    });
  }
}

export class RequestListAnnouncement {
  page = 1;
  limit = 20;
  status = "";
  isTotalData = false;
  search = "";

  constructor(fields?: Partial<RequestListAnnouncement>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
