import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/app/ui/assets/svg/arrow-left.svg'


const _hoisted_1 = { class: "py-4 flex flex-row justify-start items-center border-b border-gray-lp-400" }
const _hoisted_2 = { class: "flex flex-row py-6 h-full overflow-y-auto" }
const _hoisted_3 = { class: "w-2/3 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("img", {
        src: _imports_0,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args))),
        class: "w-5 mr-3 cursor-pointer",
        alt: "left"
      }),
      _createVNode(_component_skeleton, {
        height: "2rem",
        width: "15rem"
      })
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_skeleton, {
          width: "7.5rem",
          height: "1.2rem"
        }),
        _createVNode(_component_skeleton, {
          width: "11.5rem",
          height: "1.3rem",
          class: "mt-2"
        }),
        _createVNode(_component_skeleton, {
          width: "7.5rem",
          height: "1.2rem",
          class: "mt-8"
        }),
        _createVNode(_component_skeleton, {
          width: "33rem",
          height: "6rem",
          class: "mt-2"
        }),
        _createVNode(_component_skeleton, {
          width: "25rem",
          height: "1.2rem",
          class: "mt-8"
        }),
        _createVNode(_component_skeleton, {
          width: "11.5rem",
          height: "1.3rem",
          class: "mt-2"
        })
      ])
    ])
  ], 64))
}