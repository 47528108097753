
import { Options, Vue } from "vue-class-component";
import Editor from "./Editor.vue";

@Options({
  components: {
    Editor
  },
  props: {
    maxLength: {
      default: 1000,
      type: Number
    },
    placeholder: {
      default: "Ketik disini",
      type: String
    },
    toolbar: {
      default: [
        {
          menu: "format",
          subMenu: [
            {
              className: "bold",
              style: {
                border: "1px solid #dee2e6"
              }
            },
            {
              className: "italic",
              style: {
                border: "1px solid #dee2e6"
              }
            }
          ]
        },
        {
          menu: "align",
          style: {
            border: "1px solid #dee2e6"
          },
          subMenu: [
            {
              className: "align",
              value: ""
            },
            {
              className: "align",
              value: "center"
            },
            {
              className: "align",
              value: "right"
            },
            {
              className: "align",
              value: "justify"
            }
          ]
        },
        {
          menu: "order",
          style: {
            border: "1px solid #dee2e6"
          },
          subMenu: [
            {
              className: "list",
              value: "ordered"
            },
            {
              className: "list",
              value: "bullet"
            },
            {
              className: "indent",
              value: "-1"
            },
            {
              className: "indent",
              value: "+1"
            }
          ]
        },
        {
          menu: "order",
          subMenu: [
            {
              className: "link",
              style: {
                border: "1px solid #dee2e6"
              }
            }
          ]
        }
      ],
      type: Array
    },
    value: {
      default: "",
      type: String
    }
  }
})
export default class TextEditor extends Vue {
  props: any = this.$props;
  editorData = "";

  get isMaxLengthValue() {
    if (this.valueLength > this.props.maxLength) {
      return true;
    }

    return false;
  }

  get valueLength() {
    const value = this.editorData;
    const textOnly = value.replace(/(<([^>]+)>)/gi, "");
    return textOnly.length;
  }
}
