/* eslint-disable @typescript-eslint/camelcase */
import {
  AnnouncementData,
  AnnouncementEntities
} from "@/domain/entities/Announcement";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { AnnouncementPresenter } from "../presenters/AnnouncementPresenter";
import {
  AddAnnouncementApiRequest,
  EditAnnouncementApiRequest,
  RequestListAnnouncement
} from "@/data/payload/api/AnnouncementApiRequest";
import router from "../router";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface AnnouncementState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  announcementData: AnnouncementEntities;
  announcementDetail: AnnouncementData;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "announcement" })
class AnnouncementStore extends VuexModule implements AnnouncementState {
  public isLoading = false;
  public isLoadingDetail = false;
  public announcementData = new AnnouncementEntities(new Pagination(1, 20), []);
  public announcementDetail = new AnnouncementData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    [],
    "",
    false,
    "",
    "",
    ""
  );
  public search = "";
  public filterStatus = "";
  public isError = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public openSuccess = false;
  public openDelete = false;
  public releasedDate = new Date();
  public releasedDateTime = new Date();
  public isDeleteSuccess = false;

  @Action
  public _onGetList(params: RequestListAnnouncement) {
    this.setLoading(true);
    const presenter = container.resolve(AnnouncementPresenter);
    presenter
      .getListAnnouncement(params)
      .then((res: AnnouncementEntities) => {
        this.setAnnouncementData({
          pagination: {
            page: res.pagination.page,
            limit: res.pagination.limit,
            totalData: res.pagination.totalData
          },
          data: res.data
        });
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onGetDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(AnnouncementPresenter);
    presenter
      .getDetailAnnouncement(id)
      .then((res: AnnouncementData) => {
        this.setAnnouncementDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onAdd(params: {
    name: string;
    description: string;
    releasedDate: string;
    releasedTime: string;
    userTarget: string;
    bannerImage: string;
    attachment: string;
    pinned: boolean;
  }) {
    this.setOpenModal(false);
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(AnnouncementPresenter);
    presenter
      .addAnnouncement(
        new AddAnnouncementApiRequest(
          params.name,
          params.description,
          params.releasedDate,
          params.releasedTime,
          params.userTarget,
          params.bannerImage,
          params.attachment,
          params.pinned
        )
      )
      .then(() => {
        this.setOpenSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Announcement Gagal", () =>
            this._onAdd(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onDelete(id: number) {
    this.setOpenDelete(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(AnnouncementPresenter);
    presenter
      .deleteAnnouncement(id)
      .then(() => {
        router.push("/admin/announcement");
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Hapus Announcement Gagal", () =>
            this._onDelete(id)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onDeleteEdit(id: number) {
    this.setOpenDelete(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(AnnouncementPresenter);
    presenter
      .deleteAnnouncement(id)
      .then(() => {
        this.setOpenSuccess(true);
        this.setDeleteSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Hapus Announcement Gagal", () =>
            this._onDeleteEdit(id)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onEdit(params: {
    id: number;
    name: string;
    description: string;
    releaseDate: string;
    releaseTime: string;
    userTarget: string;
    bannerImage: any;
    attachment: any;
    pinned: boolean;
    isDeleteBanner: boolean;
  }) {
    this.setOpenModal(false);
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(AnnouncementPresenter);
    presenter
      .editDetailAnnouncement(
        params.id,
        new EditAnnouncementApiRequest(
          params.name,
          params.description,
          params.releaseDate,
          params.releaseTime,
          params.userTarget,
          params.bannerImage,
          params.attachment,
          params.pinned,
          params.isDeleteBanner
        )
      )
      .then(() => {
        this.setOpenSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Announcement Gagal", () =>
            this._onEdit(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchAnnouncementList() {
    this.setFirstRequest(false);
    this._onGetList(
      new RequestListAnnouncement({
        search: this.search,
        status: this.filterStatus,
        page: this.announcementData.pagination.page,
        limit: this.announcementData.pagination.limit,
        isTotalData: true
      })
    );
  }

  @Action
  public initAnnouncement() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setFilterStatus("");
    this.setSearch("");
    this._onGetList(
      new RequestListAnnouncement({
        search: "",
        status: "",
        page: this.announcementData.pagination.page,
        limit: this.announcementData.pagination.limit,
        isTotalData: true
      })
    );
  }

  @Action
  public setFirstPage() {
    this.announcementData.pagination.page = 1;
  }

  @Action
  public selectStatusAction(params: { name: string; value: string }) {
    this.setFilterStatus(params.value);
    this.setFirstPage();
    this.fetchAnnouncementList();
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchAnnouncementList();
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public setAnnouncementData(data: AnnouncementEntities) {
    this.announcementData = data;
  }

  @Mutation
  public setAnnouncementDetail(data: AnnouncementData) {
    this.announcementDetail = data;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setFilterStatus(value: string) {
    this.filterStatus = value;
  }

  @Mutation
  public setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setOpenDelete(boolean: boolean) {
    this.openDelete = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public handleError() {
    this.setError(false);
    this.setErrorEdit(false);
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setReleasedDate(date: Date) {
    this.releasedDate = new Date(date);
  }
  @Mutation
  public setReleasedDateTime(date: Date) {
    this.releasedDateTime = new Date(date);
  }

  @Mutation
  public setDeleteSuccess(value: boolean) {
    this.isDeleteSuccess = value;
  }
}

export const AnnouncementController = getModule(AnnouncementStore);
