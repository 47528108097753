
import { Options, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import {
  dateToDateString,
  dateToTime,
  formatDateNumber,
  formatDateWithoutTime,
  formatTimeNumber
} from "@/app/infrastructures/misc/Utils";
import { AnnouncementController } from "@/app/ui/controllers/AnnouncementController";
import CheckBox from "@/app/ui/components/check-box/index.vue";
import Toggle from "@/app/ui/components/toggle/index.vue";
import TextEditor from "@/app/ui/components/text-editor/index.vue";

@Options({
  components: {
    Tooltip,
    CheckBox,
    Toggle,
    TextEditor
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.back();
  }

  // Get Data
  mounted() {
    this.getDetailData();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  getDetailData() {
    AnnouncementController._onGetDetail(this.id);
  }

  // Handle Error
  get isLoading() {
    return AnnouncementController.isLoading;
  }

  get isError() {
    return AnnouncementController.isError;
  }

  get errorCause() {
    return AnnouncementController.isErrorCause;
  }

  handleErrorClose() {
    AnnouncementController.handleError();
  }

  get isErrorEdit() {
    return AnnouncementController.isErrorEdit;
  }

  // Modal Success
  get openSuccess() {
    return AnnouncementController.openSuccess;
  }
  onCloseSuccess() {
    AnnouncementController.setOpenSuccess(false);
  }

  // Save data
  onOpenSave() {
    return AnnouncementController.setOpenModal(true);
  }
  get openSave() {
    return AnnouncementController.openModal;
  }
  onCloseSave() {
    AnnouncementController.setOpenModal(false);
  }
  goList() {
    AnnouncementController.setDeleteSuccess(false);
    this.$router.push("/admin/announcement");
  }

  onSave() {
    AnnouncementController.handleError();
    AnnouncementController._onEdit({
      id: this.detailData.id,
      name: this.detailData.name,
      description: this.description,
      releaseDate: formatDateNumber(this.releasedAt),
      releaseTime: formatTimeNumber(this.releaseTime),
      userTarget: this.target.map((item: any) => item.userTarget).join(","),
      bannerImage: this.isBannerChange
        ? this.changeBannerImage
        : this.bannerImage,
      attachment: this.modelValue ? this.modelValue : "",
      pinned: this.pinned,
      isDeleteBanner: this.isDeleteBanner
    });
  }

  // Delete data
  onDelete() {
    AnnouncementController._onDeleteEdit(this.id);
  }
  get isConfirmDelete() {
    return AnnouncementController.openDelete;
  }
  onOpenDelete(value: boolean) {
    AnnouncementController.setOpenDelete(value);
  }
  get isDeleteSuccess() {
    return AnnouncementController.isDeleteSuccess;
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Utils
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  // payload
  description = "";
  attachment = "";
  bannerImage = "";
  releasedAt: any = "";
  releaseTime: any = "";
  target: any = [];
  pinned = false;

  // data
  get detailData() {
    const detail = AnnouncementController.announcementDetail;
    this.description = detail.description;
    this.attachment = detail.attachment;
    this.bannerImage = detail.bannerImage;
    this.releasedAt = new Date(detail.releasedAt);
    this.releaseTime = new Date(detail.releasedAt);
    this.target = detail.announcementTarget.map(item => {
      return { userTarget: item.userTarget };
    });
    this.pinned = detail.pinned;
    return detail;
  }

  inputDateStart(param: Date) {
    this.releasedAt = param;
  }
  inputDateStartTime(param: Date) {
    this.releaseTime = param;
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(this.releasedAt))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidEndDate = false;
      } else if (param.getHours() > new Date().getHours() - 1) {
        this.invalidEndDate = false;
      } else {
        this.invalidEndDate = true;
      }
    }
  }

  // Select Box
  get options() {
    return [
      { name: "POS", value: "pos" },
      { name: "Subconsolidator", value: "sub-console" },
      { name: "Internal", value: "internal" },
      { name: "Consolidator", value: "console" },
      { name: "Client", value: "client" }
    ];
  }
  keyName = "name";
  keyValue = "value";
  isSelected(item: any) {
    return this.target.find((data: any) => {
      return data.userTarget === item[this.keyValue];
    });
  }
  onChooseSelect(item: any) {
    if (this.isSelected(item)) {
      const indexItemSelected = this.target.findIndex((data: any) => {
        return data.userTarget === item[this.keyValue];
      });
      this.target.splice(indexItemSelected, 1);
    } else {
      this.target.push({ userTarget: item.value });
    }
  }

  // Toggle
  onToggle(value: boolean) {
    this.pinned = value;
  }

  // Change File
  modelValue: any = "";
  isClear = false;
  maxRows = 100000;
  isDataValidate = false;
  loadingUpload = false;
  fileName: any = "";
  imagePreview: any = "";
  changeBannerImage: any = "";
  bannerError = false;
  isBannerChange = false;
  isDeleteBanner = false;

  onUploadImage(file: any) {
    if (this.isClear) this.isClear = false;
    const files = file.target.files[0];
    if (files && files.size < 5000001) {
      this.imagePreview = URL.createObjectURL(files);
      this.changeBannerImage = files;
      this.bannerError = false;
      this.isBannerChange = true;
    } else {
      this.bannerError = true;
      this.isBannerChange = false;
    }
  }

  deleteBannerImage() {
    this.isDeleteBanner = true;
    this.isBannerChange = true;
    this.bannerImage = "";
  }

  setDefaultImage() {
    this.imagePreview = "";
    this.bannerError = false;
    this.changeBannerImage = "";
    this.isBannerChange = false;
  }

  onUploadPdf(file: any) {
    if (this.isClear) this.isClear = false;
    const files = file.target.files[0];
    if (files.size > 1024 * 1024 * 16) this.isDataValidate = true;
    else this.setValue(files);
  }

  setValue(file: any) {
    this.fileName = file.name;
    this.modelValue = file;
    this.isDataValidate = false;
  }

  setDefault() {
    this.modelValue = "";
    (this.$refs.file as HTMLFormElement).value = "";
    this.fileName = "";
    this.isDataValidate = false;
    this.loadingUpload = false;
  }

  // Watch isClear changed
  updated() {
    if (this.isClear) this.setDefault();
  }

  inputFocus = false;
  invalidEndDate = false;
}
