
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { PropType } from "vue";

@Options({
  components: {
    Skeleton
  },
  props: {
    onBack: {
      type: Function,
      default: Function as PropType<() => void>
    }
  }
})
export default class PageAddLazyload extends Vue {}
