import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/app/ui/assets/svg/arrow-left.svg'


const _hoisted_1 = { class: "flex flex-row justify-between items-center border-b border-gray-lp-400" }
const _hoisted_2 = { class: "flex flex-row my-4 w-3/5" }
const _hoisted_3 = { class: "font-semibold text-20px mr-4 text-black-lp-200 title-layout" }
const _hoisted_4 = { class: "flex flex-row py-6 h-full" }
const _hoisted_5 = { class: "w-full px-4 text-12px xxl:text-14px overflow-y-auto pb-28" }
const _hoisted_6 = { class: "w-3/5 space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_PageAddLazyload = _resolveComponent("PageAddLazyload")!
  const _component_ButtonFill = _resolveComponent("ButtonFill")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          onCancel: _ctx.handleErrorClose,
          errorType: _ctx.errorType,
          onTryAgain: _ctx.onTryAgain,
          isLoading: _ctx.isLoading,
          modal: ""
        }, null, 8, ["onCancel", "errorType", "onTryAgain", "isLoading"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_PageAddLazyload, {
          key: 1,
          onBack: _ctx.onBack
        }, null, 8, ["onBack"]))
      : (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
          _createVNode("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              _createVNode("img", {
                src: _imports_0,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args))),
                class: "w-5 mr-3 cursor-pointer",
                alt: "left"
              }),
              _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getTitle), 1)
            ]),
            _createVNode("div", null, [
              _createVNode(_component_ButtonFill, {
                title: "Simpan",
                onClick: _cache[2] || (_cache[2] = () => _ctx.onConfirmSave(true)),
                class: "px-8",
                isDisabled: _ctx.isDisabled
              }, null, 8, ["isDisabled"])
            ])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "data")
              ])
            ])
          ])
        ], 64)),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onBack,
      title: `Buat ${_ctx.getParent} Selesai`,
      message: `${_ctx.getParent} “${_ctx.keyName}” berhasil dibuat`,
      image: "image-modal-success",
      textSuccess: "Oke",
      class: "px-8"
    }, null, 8, ["onSubmit", "title", "message"]), [
      [_vShow, _ctx.isSuccess]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onSave,
      onClose: () => _ctx.onConfirmSave(false),
      title: `Konfirmasi Pembuatan ${_ctx.getParent} baru`,
      message: `Anda yakin akan menyimpan ${_ctx.getParent} baru “${_ctx.keyName}”`,
      image: "are-you-sure",
      textSuccess: "Simpan",
      textCancel: "Kembali",
      class: "px-8",
      isLoading: _ctx.isLoading
    }, null, 8, ["onSubmit", "onClose", "title", "message", "isLoading"]), [
      [_vShow, _ctx.isConfirm]
    ])
  ], 64))
}