
import { Vue, Options } from "vue-class-component";
import PageAddLazyload from "@/app/ui/components/lazy-load/page-add-lazy-load.vue";
import { PropType } from "vue";

@Options({
  components: {
    PageAddLazyload
  },
  props: {
    onBack: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    errorType: {
      default: "",
      type: String
    },
    keyName: {
      default: "",
      type: String
    },
    onTryAgain: {
      type: Function,
      required: true
    },
    isConfirm: {
      type: Boolean,
      default: true
    },
    isSuccess: {
      type: Boolean,
      default: true
    },
    onSave: {
      default: Function as PropType<() => void>,
      type: Function
    },
    handleErrorClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onConfirmSave: {
      default: Function as PropType<() => void>,
      type: Function
    }
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    getParent() {
      return this.$route.meta.parent;
    }
  }
})
export default class AddLayout extends Vue {}
