
import { PropType } from "vue";
import { Vue, Options, prop } from "vue-class-component";
import Close from "@/app/ui/components/icons/modules/close.vue";

class Props {
  image = prop<string>({
    default: "",
    type: String
  });
  onClose = prop<any>({
    default: Function as PropType<() => void>,
    type: Function
  });
}

@Options({
  name: "ImagePreview",
  components: {
    Close
  }
})
export default class extends Vue.with(Props) {}
