
import { Options, Vue } from "vue-class-component";
import {
  ellipsisString,
  firstCapitalize,
  isUrlUpdateVersion
} from "@/app/infrastructures/misc/Utils";
import { ANNOUNCEMENT } from "@/app/infrastructures/misc/RolePermission";
import { AnnouncementController } from "@/app/ui/controllers/AnnouncementController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import EmptyState from "@/app/ui/components/empty-list/index.vue";

@Options({
  components: {
    EmptyState
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isDetailAble() {
      return checkRolePermission(ANNOUNCEMENT.DETAIL);
    },
    isCreateAble() {
      return checkRolePermission(ANNOUNCEMENT.CREATE);
    }
  }
})
export default class BaseRate extends Vue {
  mounted() {
    AnnouncementController.initAnnouncement();
  }

  onRequest() {
    AnnouncementController.fetchAnnouncementList();
  }

  get firstRequest() {
    return AnnouncementController.firstRequest;
  }

  get announcement() {
    return AnnouncementController.announcementData.data;
  }

  get paginationStyle() {
    if (isUrlUpdateVersion("announcement") === "v2") {
      return "v3";
    }
    return "v1";
  }

  //Filter Search
  onSearch(value: string) {
    AnnouncementController.searchAction(value);
  }
  get searchValue() {
    return AnnouncementController.search;
  }
  clearSearch() {
    AnnouncementController.clear();
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Draft", value: "draft" },
    { name: "Sent", value: "send" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    AnnouncementController.selectStatusAction({
      name: name,
      value: value
    });
    this.statusName = name;
    this.onCloseStatusSelect();
  }

  // Table
  columns = [
    {
      name: "Announcement ID",
      styleHead: "w-2/12 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Aktivitas",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="flex flex-wrap text-black-lp-300">${firstCapitalize(
          item.name
        )}</span>`;
      }
    },
    {
      name: "Target User",
      key: "legName",
      styleHead: "w-2/12 text-left",
      styleBody: "text-left rounded px-2 py-0 flex flex-row",
      render: (item: any) => {
        const targets = item.announcementTarget
          .map((e: any) => {
            const userTarget =
              e.userTarget === "pos" ? "uppercase" : "capitalize";
            return `<div class='${userTarget} text-black-lp-300 mr-2 mb-2 flex flex-row'>
                      <div class='rounded px-2 py-0 bg-gray-lp-200'>${e.userTarget}</div>
                    </div>`;
          })
          .join(" ");

        return `<div class='flex flex-wrap font-medium'>${targets}</div>`;
      }
    },
    {
      name: "Tanggal Rilis",
      styleHead: "w-2/12 text-left",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatDate(
          item.releasedAt
        )}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-1/12 text-left",
      render: (item: any) => {
        return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center font-medium ${
            item.status.toLowerCase() === "send"
              ? "bg-green-lp-300 text-green-lp-200"
              : "bg-gray-lp-200 text-black-lp-300"
          }"
        ><span class="capitalize">${
          item.status.toLowerCase() === "send" ? "Sent" : item.status
        }</span></div></div>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-2/12 text-left",
      render: (item: any) => {
        return `<div class="flex flex-col">
        <div class='text-black-lp-300'>${formatDate(item.createdAt)}</div>
        <div class="text-gray-lp-500">${
          this.screenFHD
            ? item.createdName
            : ellipsisString(item.createdName, 14)
        }</div>
        </div>`;
      }
    }
  ];
  onClickRow(item: any) {
    this.$router.push(`/admin/announcement/${item.id}`);
  }
  goAdd() {
    this.$router.push("/admin/announcement/add");
  }

  // Pagination Table
  get pagination() {
    return AnnouncementController.announcementData.pagination;
  }

  //Loading
  get isLoading() {
    return AnnouncementController.isLoading;
  }

  //Error
  get isError() {
    return AnnouncementController.isError;
  }

  //Error Cause
  get errorCause() {
    return AnnouncementController.isErrorCause;
  }

  // Screen Size
  get screenFHD() {
    return window.matchMedia("(min-width: 1920px)").matches;
  }
}
